$publicPath:'';

@import "~assets/css/global-variable.scss";
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.g-main-box {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fafafa;
  .u-main {
    display: flex;
    //flex: 1;
    width: 100%;
    flex-direction: column;
    transition: all ease 0.3s;
    &[data-mini] {
      width: 100%;
    }
    .m-header {
    }
    .u-body {
      padding: 64px 30px 0;
      flex: 1;
      background: url("~assets/img/page-bg.png") repeat left top/100% 900px !important;
      background-attachment: fixed;
      box-sizing: border-box;
      position: relative;

      overflow-x: hidden;
      overflow-y: auto;

      .u-bread {
        position: absolute;
        top: 18px;
        left: 20px;
        .u-refresh {
          float: left;
          cursor: pointer;
          color: $fc-theme;
        }
        .u-pos {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
}

.client-header {
    width: 100%;
    min-width: 1400px;
    background: #0c1245;
    color: #ffffff;
    position: fixed;
    top: 0px;
    display: flex;
    height: 64px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 99;
    transition: all 500ms;

    &.gradient {
        background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.3) 0%,
                        rgba(5, 7, 15, 0) 100%
        );
    }

    .logo {
        width: 91px;
        height: 32px;
        background: url("~assets/img/logo.png") no-repeat;
        background-size: 100%;
        /* transform: scale(0.8); */
    }
    .lang-icon {
        display: inline-block;
        width: 16px;
        height: 11px;
        img {
            width: 100%;
        }
    }
    .lang {
        border: solid 1px #747474;
        padding: 4px 8px;
        border-radius: 2px;
    }
    .avator {
        width: 32px;
        height: 32px;
        background: #b1e7ff;
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }
    .client-header-left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;
        font-size: 16px;
        width: 120px;

        .client-header-left-box {
            position: absolute;
            top: 0;
            left: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;

            span.left-item {
                font-size: 14px;
                color: #ffffff;
                margin-left: 30px;
                margin-right: 30px;
                user-select: none;
                cursor: pointer;
                i {
                    line-height: 50px;
                }
                &:first-child {
                    margin-left: 0px;
                }
            }
        }
    }

    .client-header-right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
        cursor: pointer;
        font-size: 16px;
        width: 120px;
        position: relative;

        .client-header-right-box {
            position: absolute;
            top: 0;
            right: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
        span.right-item {
            font-size: 14px;
            color: #ffffff;
            margin-left: 30px;
            margin-right: 30px;
            cursor: pointer;

            &:last-child {
                margin-right: 0px;
            }
        }
        span.user-box {
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;
        }
        .el-dropdown-link {
            cursor: pointer;
        }
        .el-dropdown {
            color: #ffffff;
        }
    }

    .client-header-main {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        img {
            width: 313px;
            height: 40.14px;
        }
    }
}

